import { themeVariants } from 'app/components/MatxLayout/settings'

const useCurrentTheme = () => {
  const currentTheme =
    process.env.REACT_APP_THEME_NAME ||
    themeVariants.find(({ rule }) => rule.test(window.location.hostname))?.theme

  return currentTheme
}

export default useCurrentTheme
