import React from 'react'
import {
  Select as MaterialSelect,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core'

const Select = ({ options, label, children, ...props }) => {
  return (
    <FormControl
      style={{ width: '100%' }}
      variant="outlined"
      size="small"
      label={label}
    >
      {!!label && <InputLabel margin="dense">{label}</InputLabel>}
      <MaterialSelect {...props}>
        {children}
        {options?.map(({ value, label, display }) => (
          <MenuItem value={value}>{label || display}</MenuItem>
        ))}
      </MaterialSelect>
    </FormControl>
  )
}

export default Select
